@import "DeStyle.scss";
@import "_variables.scss";
@import url("https://fonts.googleapis.com/css?family=DotGothic16&display=swap");

html,
body {
    height: 100%;
}

body {
    background: #000;
    color: #fff;
    font-family: "DotGothic16", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;

    @include tablet-min {
        font-size: 16px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #000 url(../img/bg-root.png) center / cover;
}

.App {
    width: 800px;
    height: 100%;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: #000;
}

.intro-box {
    background: #4ab5ce url("../img/bg-intro.png") no-repeat bottom / contain;
    text-align: center;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    position: relative;
    overflow: hidden;

    .cloud {
        position: absolute;
        top: 15%;
        left: 0;
        display: block;
        background: url("../img/cloud.png") repeat-x top / contain;
        animation-duration: 30s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        &:nth-of-type(2) {
            top: 40%;
            animation-duration: 20s;
        }
    }

    #intro-logo-box {
        position: absolute;
        top: 200%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        width: 80%;
    }
}

.intro-repeat {
    position: absolute;
    bottom: -100%;
    left: 20%;
    transform: (-50%, -50%);
    animation: introRepeat 2s forwards;
    width: 360px;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    z-index: 1000;

    @include laptop-min {
        width: 400px;
        left: 30%;
    }
    @keyframes introRepeat {
        0% {
            bottom: -100%;
        }

        25% {
            bottom: -5%;
        }

        85% {
            bottom: -5%;
        }

        100% {
            bottom: -100%;
        }
    }

    p {
        border: solid 2px #fff;
        background: #000;
        color: #fff;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 5px;
        @include tablet-min {
            padding: 20px;
        }
    }

    .kuma-zoom {
        width: 100px;
        height: auto;
        @include tablet-min {
            width: 140px;
        }
        @include laptop-min {
            width: 180px;
        }
    }
}

header,
.header-skip {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 0 0;
    position: fixed;
    width: 800px;
    max-width: 100%;
    z-index: 100;
    top: 10px;
    @include tablet-min {
        top: 20px;
    }
    button {
        background: #000;
        color: #fff;
        border: solid 2px #fff;
        border-radius: 7px;
        padding: 15px 10px;
        line-height: 1;

        @include tablet-min {
            border: solid 4px #fff;
            border-radius: 10px;
            padding: 15px 20px;
        }
    }
}

a.link-text {
    color: red;
}

.normal-box {
    border: solid 2px #fff;
    border-radius: 10px;
    padding: 10px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.7) 100%
    );
    position: relative;
    text-align: left;
    max-width: 640px;
    @include mobile-min {
        border: solid 4px #fff;
        padding: 20px;
    }

    h2 {
        position: absolute;
        font-size: 14px;
        padding: 0 5px;
        top: -2px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1;
        text-align: center;
        width: 90%;
        @include mobile-min {
            font-size: 22px;
        }
        span {
            background: #000;
            display: inline-block;
            text-align: left;
        }
    }
    p {
        word-break: break-all;
    }

    ul {
        list-style: none;

        li {
            padding: 10px 10px 10px 20px;
            position: relative;

            &:hover,
            &.selected {
                cursor: pointer;

                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 8px;
                    border-color: transparent transparent transparent #fff;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -6px;
                }
            }

            &:hover:before {
                animation: blinkMenu 1s infinite;

                @keyframes blinkMenu {
                    0% {
                        opacity: 1;
                    }

                    49% {
                        opacity: 1;
                    }

                    50% {
                        opacity: 0;
                    }

                    99% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.sound-select-box {
    max-width: 100%;
    width: 200px;
    margin: 0 auto;
}

.talk-box {
    z-index: 10;
    border: solid 2px #fff;
    border-radius: 10px;
    width: 90%;
    padding: 0 20px;
    position: absolute;
    top: 100px;
    //margin: 0 5%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.7) 100%
    );
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    //  max-height: 80%;
    max-width: 480px;
    h2 {
        font-size: 16px;
    }
    .box-inner {
        overflow-y: auto;
        padding: 20px 0 0;
    }

    @include tablet-min {
        top: 120px;
    }
}

.box-inner {
    margin: 20px 10px;

    p {
        padding: 10px 0;
        line-height: 1.6;
    }
}

.ward-select {
    list-style: none;
    //margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    li {
        position: relative;
        width: 50%;

        &:hover,
        &.selected {
            cursor: pointer;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 8px;
                border-color: transparent transparent transparent #fff;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -6px;
            }
        }

        &:hover:before {
            animation: blinkMenu 1s infinite;

            @keyframes blinkMenu {
                0% {
                    opacity: 1;
                }

                49% {
                    opacity: 1;
                }

                50% {
                    opacity: 0;
                }

                99% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
}

#intro-logo-box {
    margin: 0 auto;

    h2 {
        white-space: nowrap;
        font-size: 18px;
    }

    #intro-button-box {
        opacity: 0;
        transition: opacity 1s;
        margin-right: auto;
        margin-left: auto;

        &.show {
            opacity: 1;
        }

        @include mobile-min {
            width: 400px;
        }
    }
}

.main-menu {
    padding: 15px 5px;
    border: solid 2px #fff;
    border-radius: 10px;
    background: #000;
    margin: 0 auto;

    @include desktop-min {
        top: 20px;
        right: 20px;
        padding: 15px;
        // border: solid 4px #fff;
    }

    h2 {
        text-align: center;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        background: #000;
        padding: 0 10px;
        line-height: 1;

        @include desktop-min {
            font-size: 18px;
        }
    }

    ul {
        display: flex;
        padding: 10px;
    }

    li {
        padding: 0 12px;
        position: relative;
        @include desktop-min {
            padding: 0 15px;
        }

        &:hover,
        &.selected {
            cursor: pointer;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 8px;
                border-color: transparent transparent transparent #fff;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -6px;
            }
        }

        &:hover:before {
            animation: blinkMenu 1s infinite;

            @keyframes blinkMenu {
                0% {
                    opacity: 1;
                }

                49% {
                    opacity: 1;
                }

                50% {
                    opacity: 0;
                }

                99% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
}

.area-box {
    margin: 0 auto;
}

.area-bg-top,
.area-bg-bottom {
    position: absolute;
    width: 100%;
    top: 0;
    background-size: contain;
    background-position: bottom;
    // z-index: -1;
    max-width: 800px;
}

.area-bg-bottom {
    top: auto;
    background-position: top;
}

.area-text {
    background: rgba(0, 0, 0, 0.5);
    border: solid 2px #fff;
    border-radius: 7px;
    display: inline-block;
    position: absolute;
    font-size: 13px;
    padding: 4px 5px;
    pointer-events: none;
    z-index: 3;
    white-space: nowrap;

    @include laptop-min {
        font-size: 16px;
        border-radius: 10px;
        padding: 7px 15px;
    }
}

#area-nishiku {
    top: 25%;
    left: 20%;
    transform: translate(-50%, -50%);
}

#area-kitaku {
    top: 20%;
    left: 70%;
    transform: translate(-50%, -50%);
}

#area-tarumiku {
    top: 65%;
    left: 0%;
}

#area-sumaku {
    top: 72%;
    left: 17%;
}

#area-nagataku {
    top: 65%;
    left: 30%;
}

#area-hyogoku {
    top: 76%;
    left: 41%;
}

#area-chuoku {
    top: 68%;
    left: 56%;
}

#area-nadaku {
    top: 62%;
    left: 72%;
}

#area-higashinadaku {
    top: 75%;
    left: 92%;
    transform: translate(-50%, -50%);
}

.svg-area-select {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    &:hover {
        cursor: pointer;
    }
    rect,
    polygon {
        opacity: 0;
    }
}

.character-img {
    //width: 2.963%;
    width: 5%;
    //width: 2.5%; //PC
    height: 8.7%;
    //height: 6.667%; //PC
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.5s, top 0.5s;
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 200%;
    text-indent: -9999px;
    pointer-events: none;
    z-index: 2;
}

.csv-list {
    width: 200px;
    height: 200px;
    overflow-y: auto;
}

.openig-talk-box {
    opacity: 0;
    animation: showOpeningTalk 2s forwards;

    @keyframes showOpeningTalk {
        0% {
            opacity: 0;
        }

        90% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

.img-face {
    text-align: center;
    display: flex;
    justify-content: center;

    img {
        width: 100px;
        height: auto;
        margin: 10px 5px;
    }
}

.cursor-triangle {
    width: 40px;
    height: 20px;
    padding: 20px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;

    &:before {
        position: absolute;
        left: 0;
        top: 50%;
        content: "";
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 15px solid #fff;
        animation: animeFlash 1.5s infinite;

        @keyframes animeFlash {
            0% {
                visibility: hidden;
            }

            49% {
                visibility: hidden;
            }

            50% {
                visibility: visible;
            }

            99% {
                opacity: visible;
            }

            100% {
                visibility: hidden;
            }
        }
    }
}

.box-swing,
.box-swing-late {
    animation: animeSwing 0.5s 1s forwards;
}

.box-swing-late {
    animation: animeSwing 0.5s 3s forwards;
}

@keyframes animeSwing {
    0% {
        rotate: 2deg;
    }

    10% {
        rotate: -2deg;
    }

    20% {
        rotate: 2deg;
    }

    30% {
        rotate: -2deg;
    }

    40% {
        rotate: 2deg;
    }

    50% {
        rotate: -2deg;
    }

    60% {
        rotate: 2deg;
    }

    70% {
        rotate: -2deg;
    }

    80% {
        rotate: 2deg;
    }

    90% {
        rotate: -2deg;
    }

    100% {
        rotate: 0;
    }
}

.shops-area-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    img {
        transition: 0.2s;

        &:hover,
        &:active {
            animation: imgAnime 1s infinite;
            transform: scale(1.2);
            cursor: pointer;
        }

        @keyframes imgAnime {
            0% {
                filter: drop-shadow(0 0 0 #fff);
            }
            50% {
                filter: drop-shadow(0 0 4px #fff);
            }
            100% {
                filter: drop-shadow(0 0 0 #fff);
            }
        }
    }
}

.shop-icon-1 {
    position: absolute;
    display: block;
}

.img-map {
    display: none;
    position: absolute;
    z-index: 1;
    animation: mapAnime2 0.3s linear;
}
#img-map-kobe {
    display: block;
    position: relative;
    z-index: 0;
    background-color: #84c600;
    //mix-blend-mode: multiply;
}

@keyframes mapAnime {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes mapAnime2 {
    0% {
        opacity: 1;
        display: block;
    }
    99% {
        display: block;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
