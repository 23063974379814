@charset "utf-8";
/* Scss Document */

$text-color: #222 !default;
$site-color1:#f4f4f4 !default;
$site-color2:#550071 !default;
$site-color3:#efe5f3 !default;
$site-color4:#123c7b !default;
$site-color5:#e4e9f9 !default;
$site-color6:#5288d9 !default;
$link-color: $site-color2 !default;
$gray-color: #ddd !default;

$font-en: "Roboto",
"Arial",
"游ゴシック",
YuGothic,
"ヒラギノ角ゴシック Pro",
"Hiragino Kaku Gothic Pro";
$font-default:'Noto Sans JP',
"游ゴシック",
YuGothic,
"ヒラギノ角ゴシック Pro",
"Hiragino Kaku Gothic Pro",
serif;
$font-jp :'Noto Serif JP',
serif;

@mixin gradient-blue {
  background: rgb(0, 146, 203);
  background: linear-gradient(0deg, rgba(0, 146, 203, 1) 0%, rgba(3, 161, 223, 1) 100%);
}

$uhd-min: 1680px;
$qhd-max: 1679px;
$qhd-min: 1440px;
$fhd-max: 1439px;
$fhd-min: 1280px;
$hd-max: 1279px;
$hd-min: 1080px;
$desktop-max: 1079px;
$desktop-min: 992px;
$laptop-max: 991px;
$laptop-min: 768px;
$tablet-max: 767px;
$tablet-min: 576px;
$mobile-max: 575px;
$mobile-min: 480px;

@mixin uhd-min {
  @media screen and (min-width: $uhd-min) {
    @content;
  }
}

@mixin qhd-min {
  @media screen and (min-width: $qhd-min) {
    @content;
  }
}

@mixin fhd-min {
  @media screen and (min-width: $fhd-min) {
    @content;
  }
}

@mixin hd-min {
  @media screen and (min-width: $hd-min) {
    @content;
  }
}

@mixin desktop-min {
  @media screen and (min-width: $desktop-min) {
    @content;
  }
}

@mixin laptop-min {
  @media screen and (min-width: $laptop-min) {
    @content;
  }
}

@mixin tablet-min {
  @media screen and (min-width: $tablet-min) {
    @content;
  }
}

@mixin mobile-min {
  @media screen and (min-width: $mobile-min) {
    @content;
  }
}

@mixin qhd-max {
  @media screen and (max-width: $qhd-max) {
    @content;
  }
}

@mixin fhd-max {
  @media screen and (max-width: $fhd-max) {
    @content;
  }
}

@mixin hd-max {
  @media screen and (max-width: $hd-max) {
    @content;
  }
}

@mixin desktop-max {
  @media screen and (max-width: $desktop-max) {
    @content;
  }
}

@mixin laptop-max {
  @media screen and (max-width: $laptop-max) {
    @content;
  }
}

@mixin tablet-max {
  @media screen and (max-width: $tablet-max) {
    @content;
  }
}

@mixin mobile-max {
  @media screen and (max-width: $mobile-max) {
    @content;
  }
}